import React from "react";
import { Grid, Paper, Typography, useTheme, useMediaQuery } from "@mui/material";

const About = () => {

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    // <ThemeProvider theme={theme}>
    <Grid container spacing={5} m={isMobile?-3:-6} direction="row"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
      sx={{p: isMobile? 4: 10}}

    >
      <Grid item xs={12} xl={6} >
        {/* <Box > */}
        <Paper sx={{ p: 6 }}>
          <Typography variant="h3">About Us</Typography>
          <p style={{ textAlign: "left" }}>
            The GCB Risk App is for the general public to measure their oral
            cancer risk. It is also platform for the professionals to assess,
            follow up and record tobacco consuming patients
          </p>
        </Paper>
        {/* </Box> */}
      </Grid>
      <Grid item xs={12} xl={6}>
        <Grid container rowSpacing={2}>

          <Grid item xs={12} xl={12}>
            <Paper sx={{ p: 2 }}>
              <Grid container rowSpacing={5} columnSpacing={5} direction="row"
                justifyContent="center"
                alignItems="center"
                height="100%"
                width="100%"

              >
                <Grid item>


                </Grid>
                <Grid item>
                  <Typography variant="h5">Oral Cancer and India</Typography>



                </Grid>
              </Grid>
              <p style={{ marginLeft: "8px", textAlign: "center" }}>
                Oral cancer is among the top three types of cancer in India, about
                1,36,000 new cases and about 57,000 deaths are recorded every
                year.
              </p>
              <hr className="dropdown-divider"></hr>
              <p style={{ marginLeft: "8px", textAlign: "center" }}>
                In 80% of the cases aetiology of the disease is Tobacco
              </p>
              <hr className="dropdown-divider"></hr>
              <p style={{ marginLeft: "8px", textAlign: "center" }}>
                So it is in fact the most preventable type of cancer
              </p>
              <hr className="dropdown-divider"></hr>
              <p style={{ marginLeft: "8px", textAlign: "center" }}>
                This app is meant for patients and their families to help
                individuals give up their habit
              </p>



            </Paper>
          </Grid>

          <Grid item xs={12} xl={12}>
            <Paper sx={{ p: 2 }}>
              <Grid container rowSpacing={5} columnSpacing={5} direction="row"
                justifyContent="center"
                alignItems="center"
                height="100%"
                width="100%"

              >
                <Grid item>


                </Grid>
                <Grid item>
                  <Typography variant="h5"> Mission</Typography>

                </Grid>
              </Grid>
              <p style={{ marginLeft: "8px", textAlign: "center" }}>
                To take the help of public systems like government schools, PHCs
                where the app would be available in tablets and high school
                students through NSS or NCC, Asha Workers of rural areas and
                cities can measure the risk of the oral cancer in tobacco users in
                their vicinities and in turn learn about ill effects of oral
                cancer and also help in early detection.
              </p>
            </Paper>
          </Grid>

          <Grid item xs={12} xl={12}>
            <Paper sx={{ p: 2 }}>
              <Grid container rowSpacing={5} columnSpacing={5} direction="row"
                justifyContent="center"
                alignItems="center"
                height="100%"
                width="100%"

              >
                <Grid item>


                </Grid>
                <Grid item>
                  <Typography variant="h5"> Vision</Typography>

                </Grid>
              </Grid>
              <p style={{ marginLeft: "8px", textAlign: "center" }}>
                To reduce the oral cancer burden in the society.</p>
              <hr className="dropdown-divider"></hr>
              <p style={{ marginLeft: "8px", textAlign: "center" }}>
                Our vision is to democratize the data on oral potentially
                malignant disorders and help in reducing the oral cancer
                incidence
              </p>

            </Paper>
          </Grid>



        </Grid>


      </Grid>
      
    </Grid>

  );
};

export default About;
