import React, { useState, useRef, useEffect } from "react";
import Webcam from "react-webcam";
import above_teeth from "../assets/above_teeth.png";
import left_tongue from "../assets/left_tongue.png";
import below_teeth from "../assets/below_teeth.png";
import left_inner_cheek from "../assets/left_inner_cheek.png";
import palate from "../assets/palate.png";
import right_inner_cheek from "../assets/right_inner_cheek.png";
import right_tongue from "../assets/right_tongue.png";
import tongue from "../assets/tongue.png";
import under_tongue from "../assets/under_tongue.png";
import uvula from "../assets/uvula.png";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Grid, Button, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import serverCommunicator from "../api/api";


const VideoPage = () => {
  const webcamRef = useRef(null);
  const [webcamActive, setWebcamActive] = useState(false);
  const navigate = useNavigate();
  const [ov, setOv] = useState(false);
  const [select, setSelect] = useState(tongue);
  const [currentSection, setCurrentSection] = useState("tongue")
  const [filesList, setFilesList] = useState([])
  let hid = document.getElementById("hid");
  const instructions = {
    tongue, 
    right_tongue,
    left_tongue,
    under_tongue,
    above_teeth,
    below_teeth,
    left_inner_cheek,
    right_inner_cheek,
    palate,
    uvula
  }

  const instructionImages = ["tongue", "right_tongue", "left_tongue", "under_tongue", "above_teeth", "below_teeth", "left_inner_cheek", "right_inner_cheek", "palate", "uvula"]
  // const instructionImages = [tongue]


  const [currentSelectionNumber, setCurrentSectionNumber] = useState(-1)
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const location = useLocation();
  const reportId = location.state;
  const [count, setCount] = useState(instructionImages.length);

  useEffect(()=>{
    if(count===0){
      serverCommunicator.finishUpload(reportId)
      .then((res)=>{}) //What to do here?
      .catch((err)=>{console.log(err)})
    }
  },[count])

  useEffect(() => {

    if (filesList.length == instructionImages.length) {
      filesList.map((file, index) => {
        const date = new Date()

        const image_file = new File([convertBase64ToFile(file.image)], `BerryCare_Screening${instructionImages[index]}${date.getFullYear()}${date.getMonth()}${date.getDate()}_${date.getHours()}${date.getMinutes()}${date.getSeconds()}_.png`)

        serverCommunicator.uploadImages(image_file, reportId, currentSection)
        .then((response)=>{
          setCount((prevCount)=> prevCount-1)})
        .catch(err => {
          console.log(err)
        })

        // uploadSingleImage(file.image, 471)
      })

    }

    else {
      setCurrentSectionNumber(currentSelectionNumber + 1)
      setCurrentSection("" + `${instructions[instructionImages[currentSelectionNumber + 1]]}`)
      setSelect(instructions[instructionImages[currentSelectionNumber + 1]])
    }

  }, [filesList])
  const handleStartCapture = () => {
    setWebcamActive(true);
  };

  // const handleStopCapture = () => {
  //   setWebcamActive(false);
  // };
  const convertBase64ToFile = (image) => {
    const byteString = atob(image.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    const newBlob = new Blob([ab], {
      type: 'image/jpeg',
    });
    return newBlob;
  };

  
  const handleCapture = async () => {
    // Capture the photo here and send it to the API
    
    
    const imageSrc1 = webcamRef.current.getScreenshot();

    
    const filesListUpdate = filesList.map(file => file.name === currentSection ? { ...file, "image": imageSrc1 } : file)

    setFilesList(filesListUpdate)

    if (!filesList.some(file => file.name === currentSection)) {
      setFilesList([
        ...filesList,
        { "name": currentSection, "image": imageSrc1 }
      ])
    }
  };

  const handleChange = async (item) => {
    const id = item.target.id

    switch (id) {

      case above_teeth:
        setCurrentSection("above_teeth")
        setSelect(above_teeth);
        break;
      case left_tongue:
        setCurrentSection("left_tongue")

        setSelect(left_tongue);
        break;

      case below_teeth:
        setCurrentSection("below_teeth")

        setSelect(below_teeth);
        break;

      case left_inner_cheek:
        setCurrentSection("left_inner_cheek")

        setSelect(left_inner_cheek);
        break;

      case palate:
        setCurrentSection("palate")

        setSelect(palate);
        break;

      case right_inner_cheek:
        setCurrentSection("right_inner_cheek")

        setSelect(right_inner_cheek);
        break;

      case right_tongue:
        setCurrentSection("right_tongue")

        setSelect(right_tongue);
        break;

      case tongue:
        setCurrentSection("tongue")

        setSelect(tongue);
        break;

      case under_tongue:
        setCurrentSection("under_tongue")

        setSelect(under_tongue);
        break;

      case uvula:
        setCurrentSection("uvula")

        setSelect(uvula);
        break;


    }
  }

  return (
    <Grid
      container
      margin={0}
      rowSpacing={1}
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{ p: isMobile ? 2 : 10, pageBreakInside: 'avoid' }}

    >
      <Grid item xs={12} xl={8}>
        <div className="webcam-container">
          <Webcam
            audio={true}
            ref={webcamRef}
            onUserMedia={() => handleStartCapture()}
            mirrored={true}
            screenshotFormat="image/png" />

          {webcamActive && (
            <img src={select} className="overlay-container" alt="Overlay" id="hid" />

          )}

        </div>
      </Grid>
      <Grid item xs={12} xl={4}>
        <Button variant="contained" color="secondary" onClick={handleCapture} >
          Capture Photo
        </Button>
      </Grid>

      <Grid item xs={12} xl={12}>
        {instructionImages.map((instructionImage, index) => {

          return (<button className="select" onClick={(id) => handleChange(id)} >
            <img src={filesList[index] == undefined ? instructions[instructionImage] : filesList[index]["image"]} alt="" id={instructionImage} />
          </button>)
        }


        )}
      </Grid>

    </Grid>
  );
};

export default VideoPage;
