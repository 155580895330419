// src/components/MainPage.js
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";

import { Grid, Typography, Paper, List, useMediaQuery, useTheme, Card, ListItem, ListItemText } from "@mui/material";
import API from "../api/api";
import { BarChart } from '@mui/x-charts/BarChart';
import Box from '@mui/material/Box';
import serverCommunicator from "../api/api";
import HeroSection from "../components/heroSection";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import hero from "../assets/main_page_hero_header.jpg"
import habits_image from "../assets/habits_image.jpg";
import berrycare_hero from "../assets/design.png";
import age_gender from "../assets/age_gender.jpg"
import { useNavigate } from "react-router-dom";
import ScrollAnimation from 'react-animate-on-scroll';
import Slide from '@mui/material/Slide';
import HeroSection2 from "../components/HeroSection2";
import dentistHero from "../assets/115.-Man-Doctor_4.png";
import heroImage from "../assets/hero_image.png";

const MainPage = () => {
  const formData = useSelector((state) => state.formData);
  const [chart1Data, setChart1Data] = useState({})
  const theme = useTheme();
  const navigate = useNavigate();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    // TODO: find out if we can just store this and refresh every 5 mins if the user is still on the page
    serverCommunicator.dashboardStats()
      .then((response) => {

        setChart1Data(response.data.people_stats)
      })
      .catch((err) => {
        console.log(err)
      })

    // API.dashboardStats()
  }, [])

  const containerRef = React.useRef(null);

  const icon = (
    <Paper sx={{ m: 1, width: 100, height: 100 }} elevation={4}>
      <svg>
        <Box
          component="polygon"
          points="0,100 50,00, 100,100"
          sx={{
            fill: (theme) => theme.palette.common.white,
            stroke: (theme) => theme.palette.divider,
            strokeWidth: 1,
          }}
        />
      </svg>
    </Paper>
  );

  return (


    <Grid
      spacing={2}
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      height="100%"
      width="100%"
      sx={{ backgroundColor: theme.palette.quarternary.main }}
    >

      {/* HERO SECTION */}
      <Grid item xs={12} sm={12} xl={12} md={12} >

        <HeroSection2
          hero={heroImage}
          height={isMobile ? "20vh" : "50vh"}
          width={isMobile ? "20vh" : "60vh"}
          primaryText="PREVENTION IS"
          secondaryText="BETTER THAN CURE"
          subText="Assess your oral cancer risk, today"
          button=
          {<Button variant="contained" color="secondary" onClick={() => navigate('/multi_form')}> START ASSESSMENT </Button>}
          bannerHeight={isMobile ? "5vh" : "20vh"}
          alignment={isMobile ? "center" : "flex-start"}
          stackJustifyContent = {isMobile?"center":"center"}
          displayCenterAligned={isMobile?true:false}          
        />

      </Grid>

      {/* WHAT IS GCB APP  */}
      <Grid item xs={12} sm={12} xl={12} md={12} sx={{ p: { xs: 0, md: 3, lg: 10, xl: 10 } }}>
        {/* <Grid container> */}
        <Card elevation={2} sx={{ p: 3, borderRadius: isMobile ? 0 : 2, backgroundColor: isMobile ? theme.palette.quarternary.main : 'white' }}>

          <Grid container direction="column">
            {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1, xl: 2 }}> */}
            {/* <Grid item xs={12} sm={12} xl={12} md={12} p={10}> */}
            

            <Typography variant="h3" align="center" >What is the GCB App?</Typography>
            <Typography variant="h5" color="quarternary" align="left" pb={2} pt={2} >It is an application to help:</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} xl={6} md={6}>
                <Typography color="black" variant="h6" align="left"> <CheckCircleIcon fontSize="2rem" color="primary" /> Raise awareness for oral precancer and cancer</Typography>
              </Grid>

              <Grid item xs={12} sm={12} xl={6} md={6}>
                <Typography color="black" align="left" variant="h6"> <CheckCircleIcon fontSize="2rem" color="primary" /> Measure your risk for oral cancer</Typography>
              </Grid>

              <Grid item xs={12} sm={12} xl={6} md={6}>
                <Typography color="black" align="left" variant="h6"> <CheckCircleIcon fontSize="2rem" color="primary" /> Help diagnose oral cancer</Typography>
              </Grid>

              <Grid item xs={12} sm={12} xl={6} md={6}>
                <Typography color="black" align="left" variant="h6"> <CheckCircleIcon fontSize="2rem" color="primary" /> Help diagnose potentially malignant
                  disorder/oral precancer</Typography>
              </Grid>

              <Grid item xs={12} sm={12} xl={6} md={6}>
                <Typography color="black" align="left" variant="h6"> <CheckCircleIcon fontSize="2rem" color="primary" /> Refer the patient to the correct center/team</Typography>
              </Grid>

              <Grid item xs={12} sm={12} xl={6} md={6}>
                <Typography color="black" align="left" variant="h6"> <CheckCircleIcon fontSize="2rem" color="primary" /> Help patient to monitor their habit and distract
                  them at the time of crave.</Typography>
              </Grid>

            </Grid>


            {/* </Grid> */}
            {/* </Grid> */}
          </Grid>
        </Card>
        {/* </Grid> */}



      </Grid>
      {/* LIVE INSIGHTS */}
      <Grid item xs={12} xl={12}>

        <Card elevation={0} >

          <Grid container direction="column" spacing={5} alignContent="center">
            <Grid item align="center">
              <Typography p={2} variant="h4" color="primary" align="center" >Live Insights</Typography>
              <Typography p={2} variant="h6" color="primary" align="center" >These are live statistics of people who have started their journey to better health</Typography>
            </Grid>

            <Grid item>
              <Card sx={{ borderRadius: isMobile ? 3 : 9, ml: isMobile ? 2 : 6, mr: isMobile ? 2 : 6, p: 2 }} elevation={4}>
                <Typography variant="h5" color='primary'> Gender & Age</Typography>
                <Grid container direction="row">

                  <Grid item xs={12} md={8} xl={8}>

                    {
                      isMobile ? (
                        <BarChart
                          xAxis={[{ scaleType: 'band', data: ['Males', 'Females', 'Age 30+', 'Age Below 30'] }]}
                          colors={[theme.palette.secondary.main]}
                          series={[{ data: [chart1Data.males, chart1Data.females, chart1Data.patients_above_age_filter, chart1Data.patients_below_age_filter] }]}
                          height={300}

                        />
                      ) : (
                        <Grid container direction='row'>
                          <Grid item xs={12} md={6} xl={6}>
                            <HeroSection flexDirection='column' height='40vh' hero={age_gender} />
                          </Grid>
                          <Grid item xs={12} md={6} xl={6}>
                            <BarChart
                              xAxis={[{ scaleType: 'band', data: ['Males', 'Females', 'Age 30+', 'Age Below 30'] }]}
                              colors={[theme.palette.secondary.main]}
                              series={[{ data: [chart1Data.males, chart1Data.females, chart1Data.patients_above_age_filter, chart1Data.patients_below_age_filter] }]}


                            />
                          </Grid>
                        </Grid>
                      )
                    }

                  </Grid>


                  <Grid item xs={12} md={4} xl={4}>
                    <Card elevation={1} sx={{ p: 2 }}>
                      <Typography variant="h6" color="primary">Interesting Facts</Typography>
                      <Typography color="black" p={1}>
                        <CheckCircleIcon fontSize="2rem" color="primary" />
                        In India, oral cancer represents a major health problem constituting
                        up to 40% of all cancers and is the most prevalent cancer in males
                        and the third most prevalent in females.
                      </Typography>
                      <Typography color="black" p={1}>
                        <CheckCircleIcon fontSize="2rem" color="primary" />
                        The susceptibility of women to oral diseases can be related to genetic and biological factors and men related to behavioral factors.
                      </Typography>
                      <Typography color="black" p={1}>
                        <CheckCircleIcon fontSize="2rem" color="primary" />
                        About 95 percent of oral cancers occur in people over 40 years of age.

                      </Typography>
                    </Card>
                  </Grid>
                </Grid>
              </Card>


            </Grid>

            <Grid item xs={12} xl={12} mb={2}>
              <Card sx={{ borderRadius: isMobile ? 3 : 9, ml: isMobile ? 2 : 6, mr: isMobile ? 2 : 6, p: 2 }} elevation={4}>
                <Typography variant="h5" color='primary'> Habit Details</Typography>
                <Grid container direction="row">
                  <Grid item xs={12} md={8} xl={8}>
                    {isMobile ? (

                      <BarChart
                        xAxis={[{ scaleType: 'band', data: ['Cigarette', 'Gutka/betel Quid', 'Alcohol'] }]}
                        colors={[theme.palette.info.main]}
                        height='300'
                        series={[{ data: [chart1Data.smokers, chart1Data.tobacco_consumers, chart1Data.alcohol_consumers] }]}

                      />
                    ) : (
                      <Grid container direction='row'>
                        <Grid item xs={12} md={6} xl={6}>
                          <HeroSection flexDirection='column' height='40vh' hero={habits_image} />
                        </Grid>
                        <Grid item xs={12} md={6} xl={6}>
                          <BarChart
                            xAxis={[{ scaleType: 'band', data: ['Cigarette', 'Gutka/betel Quid', 'Alcohol'] }]}
                            colors={[theme.palette.info.main]}

                            series={[{ data: [chart1Data.smokers, chart1Data.tobacco_consumers, chart1Data.alcohol_consumers] }]}

                          />
                        </Grid>

                      </Grid>
                    )}

                  </Grid>


                  <Grid item xs={12} md={4} xl={4}>
                    <Card elevation={5} sx={{ p: 2 }}>
                      <Typography variant="h6" color="primary">Interesting Facts</Typography>
                      <Typography color="black" p={1}>
                        <CheckCircleIcon fontSize="2rem" color="primary" />

                        Oral cancer is among the top three types of cancer in India, about
                        1,36,000 new cases and about 57,000 deaths are recorded every year.
                      </Typography>
                      <Typography color="black" p={1}>
                        <CheckCircleIcon fontSize="2rem" color="primary" />
                        Tobacco is considered the etiology is almost 80% of cases of oral
                        squamous cell carcinoma, making it the most preventable type of
                        cancer.
                      </Typography>
                    </Card>
                  </Grid>
                </Grid>

              </Card>


            </Grid>

          </Grid>



        </Card>


      </Grid>



      <Grid item xs={12} xl={12} sx={{ p: { xs: 2, md: 3, lg: 10, xl: 10 } }}>

        {/* <Card elevation={0} sx={{ p: 2 }}> */}
        <Grid container direction="column" spacing={5} alignContent="center">
          <Grid item>
            <Typography variant="h4" color="primary" align="center" p={2}>Vision & Mission</Typography>
          </Grid>

          <Grid item>
            <Typography variant="h6" color="black" align="justify" p={1}>
              <CheckCircleIcon fontSize="2rem" color="primary" />

              To reduce the oral cancer burden in the society.</Typography>
            <Typography variant="h6" color="black" align="justify" p={1}>
              <CheckCircleIcon fontSize="2rem" color="primary" />

              To take the help of public systems like government
              schools, PHCs where the app would be available in
              tablets and high school students through NSS or
              NCC, Asha Workers of rural areas and cities can
              measure the risk of the oral cancer in tobacco
              users in their vicinities and in turn learn about ill
              effects of oral cancer and also help in early
              detection.</Typography>
          </Grid>

        </Grid>
        {/* </Card> */}


      </Grid>


      {/* </Paper> */}

    </Grid>

  );
};

export default MainPage;
