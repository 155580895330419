import { Typography } from "@mui/material";
import Box from '@mui/material/Box';

const HeroSection = (props) => {
    const {hero, height, flexDirection, primaryText, secondaryText ,button} = props;
    return (
        <Box
            // height={isMobile ? '50vh' : '80vh'}
            // height='80vh'
            className="hero"
            p={2}
            // style={{

            // }}
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                backgroundImage: `url(${hero})`,
                backgroundSize: "cover",
                height: {height},
                borderRadius: 5,
                backgroundClip:'primary',
                backgroundColor:'primary',
                alignContent: 'flex-start',
                flexDirection: {flexDirection},
                alignItems: 'flex-start',
                justifyContent: 'center'
            }}
        >

            <Typography variant="h3" p={2} color='white'>{primaryText}</Typography>

            <Typography variant="h5" p={2} color='#adf'>{secondaryText}</Typography>
            <Typography variant="h5" p={2}>{button}</Typography>
            
            {/* <Typography variant="h5" p={2} color='primary'>TODAY</Typography> */}


        </Box>
    )
}
export default HeroSection

