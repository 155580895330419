// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Link, Routes, BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";
import { CssBaseline, AppBar, Drawer, List, ListItem, ListItemText, Toolbar, IconButton, Typography, Container, Grid, Box } from "@mui/material";
// import { Menu } from "@mui/icons-material";
import MainPage from "./pages/MainPage.js";
// import Report from "./components/Report.js";
import ReportNew from "./pages/ReportNew.js";


import "./App.css";
import WebcamOverlay from "./pages/WebcamOverlay";
import ResponsiveAppBar from "./components/ResponsiveAppBar.tsx";
import About from "./pages/About.js";

import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
// import store from './app/store'

import RiskFactors from "./pages/RiskFactors.js";
import AboutApp from "./pages/AboutApp.js";
import OralCancer from "./pages/OralCancer.js";
import { green, purple } from '@mui/material/colors';
import StepForm from "./pages/StepForm.js";
import Footer from "./components/footer.js";
// import StepForm2 from "./components/StepForm2.js";


const initialState = { formData: [] };

function formReducer(state = initialState, action) {
  switch (action.type) {
    case "ADD_FORM_DATA":
      return { ...state, formData: [...state.formData, action.payload] };
    default:
      return state;
  }
}

const store = createStore(formReducer);

const berrycareColor = {
  100: "#611b29",
  50:"#ecc8c8",
  25:"#DC6870",
  10:"#f3f3f3",
  5: "#a64c55",
  
  // 50:"#025fa1",
  // 25: "#e5dcc5"
  // 25:"#c7d3bf"
}

let theme = createTheme({
  
  palette: {
    primary: {
      main: berrycareColor[100],
    },
    secondary: {
      main: berrycareColor[50],
    },
    info: {
      main: berrycareColor[25]
    },
    tertiary: {
      main: berrycareColor[5]
    },
    quarternary: {
      main: berrycareColor[10]
    }

  },

  components: {
    // Name of the component ⚛️
    MuiButtonBase: {
      defaultProps: {
        // The default props to change
        disableRipple: false, // No more ripple, on the whole application 💣!
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
            backgroundColor: '#202020',
            color: '#fff',
          }),
        })
      }
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: berrycareColor[100]
        }
      }
    }
  },
  spacing: 8,
  typography: {
    h3: {
      // fontSize: "3.5rem",
      // Customize the font size for h1
      fontWeight: "bold",
      fontSize: '2rem', // Default font size
      '@media (max-width:600px)': {
        fontSize: '1.75rem', // Adjusted font size for small screens (assumed mobile)
      },
      color: berrycareColor[100],
      textAlign: "left", // Customize the font weight for h1
      // Add more styles as needed
      letterSpacing: '0.01em',
      marginBottom: 2, 
      '@media (max-width:600px)': {
        marginBottom:2, // Adjusted font size for small screens (assumed mobile)
      },

    },
    h4: {
      color: berrycareColor[50],
      textAlign: "left",
      fontWeight: "bold",
      letterSpacing: '0.01em',
      fontSize: '1.75rem', // Default font size
      '@media (max-width:600px)': {
        fontSize: '1.25rem', // Adjusted font size for small screens (assumed mobile)
      },

    },
    h5: {
      color: berrycareColor[100],
      textAlign: "center",
      fontWeight: "bold",
      letterSpacing: '0.01em',
      // marginBottom:'2rem'
      fontSize: '1.25rem', // Default font size
      '@media (max-width:600px)': {
        fontSize: '1rem', // Adjusted font size for small screens (assumed mobile)
      },

    },
    h6: {
      color: berrycareColor[10],
      textAlign: "center",
      letterSpacing: '0.01em',
      fontWeight: "bold",

      // marginBottom:'1rem',
      fontSize: '1rem', // Default font size
      '@media (max-width:600px)': {
        fontSize: '0.75rem', // Adjusted font size for small screens (assumed mobile)
      },
    },

    fontFamily:['system-ui'],
    
    // fontFamily: [
    //   '-apple-system',
    //   'BlinkMacSystemFont',
    //   '"Segoe UI"',
    //   'Roboto',
    //   '"Helvetica Neue"',
    //   'Arial',
    //   'sans-serif',
    //   '"Apple Color Emoji"',
    //   '"Segoe UI Emoji"',
    //   '"Segoe UI Symbol"',
      
    // ].join(','),
    
  },

});
theme = responsiveFontSizes(theme);
const App = () => {
  return (
      <BrowserRouter>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <ResponsiveAppBar />
            <Box>
  
              <Routes>
                <Route path="/" exact element={<MainPage />} />
                <Route path="/aboutapp" element={<AboutApp />} />
                <Route path="/oralcancer" element={<OralCancer />} />
                <Route path="/riskfactors" element={<RiskFactors />} />
                <Route path="/about" element={<About />} />
                <Route path="/multi_form" exact element={<StepForm/>} />
                {/* <Route path="/multi_form2" exact element={<StepForm2/>} /> */}
                {/* <Route path="/camera" element={<CameraPage/>} /> */}
                <Route path="/camera" element={<WebcamOverlay />} />
                <Route path="/report" element={<ReportNew />} />
                {/* <Route path="/report1" element={<Report />} /> */}
                </Routes>
            </Box>
            {/* <CssBaseline /> */}
            <Footer/>
          </ThemeProvider>
  
        </Provider>
      </BrowserRouter>
  
  );

}

export default App;
