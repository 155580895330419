import { Grid, Paper, Typography, Card, Box } from "@mui/material";

const IconAndContent = (props) => {

    const { title, content, icon } = props

    return (
        <Grid container direction="row" columnSpacing={1} sx={{ alignContent: 'stretch', alignItems: 'center' }}>
            <Grid item xs={1.6} xl={1} md={1} sm={1}>
                <Box display="flex" flexDirection="column" alignItems="center" alignContent="stretch">

                    <Card p={2}>
                        {icon}
                    </Card>
                </Box>
            </Grid>
            <Grid item xs={10.4} xl={11} md={11} sm={11}>
                <Paper sx={{ p: 2 }}>

                    <Typography variant="h5">{title}</Typography>



                    <Typography style={{ textAlign: "center" }}>
                        {content}
                    </Typography>
                </Paper>
            </Grid>
        </Grid>
    )
}
export default IconAndContent