import React from "react";
import { Grid, Paper, Typography, useMediaQuery, useTheme  } from "@mui/material";

const RiskFactors = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  return (
    // <ThemeProvider theme={theme}>
    <Grid container spacing={5}  m={isMobile?-3:-6} direction="row"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
      sx={{p: isMobile? 4: 10}}
    >
      <Grid item xs={12} xl={6} >
        {/* <Box > */}
        <Paper sx={{ p: 6 }}>
          <Typography variant="h3">About Risk Factors</Typography>

        </Paper>
        {/* </Box> */}
      </Grid>
      <Grid item xs={12} xl={6}>
        <Grid container rowSpacing={2}>

          <Grid item xs={12} xl={12}>
            <Paper sx={{ p: 2 }}>
              <Grid container rowSpacing={5} columnSpacing={5} direction="row"
                justifyContent="center"
                alignItems="center"
                height="100%"
                width="100%"

              >
                <Grid item>

                </Grid>
                <Grid item>
                  <Typography variant="h5">Tobacco Use</Typography>

                </Grid>
              </Grid>


              <p style={{ marginLeft: "8px", textAlign: "center" }}>
                Tobacco consumed in smoked and smokeless forms form the most
                important aetiology and it is associated about 80% of oral
                squamous cell carcinoma.
              </p>
              <hr className="dropdown-divider"></hr>
              <p style={{ marginLeft: "8px", textAlign: "center" }}>
                In India, smokeless tobacco especially Gutka, Khaini are commonly
                reported
              </p>
            </Paper>
          </Grid>

          <Grid item xs={12} xl={12}>
            <Paper sx={{ p: 2 }}>
              <Grid container rowSpacing={5} columnSpacing={5} direction="row"
                justifyContent="center"
                alignItems="center"
                height="100%"
                width="100%"

              >
                <Grid item>


                </Grid>
                <Grid item>
                  <Typography variant="h5">Alcohol</Typography>

                </Grid>
              </Grid>
              <p style={{ marginLeft: "8px", textAlign: "center" }}>
                Alcohol more commonly is used with tobacco and has a synergistic
                effect where the cause and affect becomes more stronger.
              </p>
            </Paper>
          </Grid>

          <Grid item xs={12} xl={12}>
            <Paper sx={{ p: 2 }}>
              <Grid container rowSpacing={5} columnSpacing={5} direction="row"
                justifyContent="center"
                alignItems="center"
                height="100%"
                width="100%"

              >
                <Grid item>

                </Grid>
                <Grid item>
                  <Typography variant="h5"> Other risk factors</Typography>

                </Grid>
              </Grid>
              <p style={{ marginLeft: "8px", textAlign: "center" }}>Poor Diet</p>
              <hr className="dropdown-divider"></hr>
              <p style={{ marginLeft: "8px", textAlign: "center" }}>Poor socioeconomic status</p>
              <hr className="dropdown-divider"></hr>
              <p style={{ marginLeft: "8px", textAlign: "center" }}>Older age (4th Decade and higher)</p>
              <hr className="dropdown-divider"></hr>
              <p style={{ marginLeft: "8px", textAlign: "center" }}>Poor oral hygiene/Periodontal health</p>
              <hr className="dropdown-divider"></hr>
              <p style={{ marginLeft: "8px", textAlign: "center" }}>Genetic susceptibility</p>
              <hr className="dropdown-divider"></hr>
              <p style={{ marginLeft: "8px", textAlign: "center" }}>
                High risk Human papilloma virus (secondary to unsafe sexual
                practices)
              </p>
            </Paper>
          </Grid>



        </Grid>


      </Grid>
      {/* <Grid item xs={12} xl={6}>
          <Paper>
            <Typography variant="h4" style={{ marginLeft: "5px", textAlign: "center" }}>
              Application
            </Typography>
            <p style={{ marginLeft: "8px", textAlign: "center" }}>
              This application helps you measure your oral cancer risk
            </p>
            <hr className="dropdown-divider"></hr>
            <p style={{ marginLeft: "8px", textAlign: "center" }}>
              Refer you to an appropriate centre for further management
            </p>
            <hr className="dropdown-divider"></hr>
            <p style={{ marginLeft: "8px", textAlign: "center" }}>
              The GCB app helps both the patients and professionals to follow up on their lesion size and tobacco habits
            </p>
            <hr className="dropdown-divider"></hr>
            <p style={{ marginLeft: "8px", textAlign: "center" }}>
              In future we want to develop a prediction algorithm for oral
              potentially malignant disorders and cancer through AI and ML
              platform
            </p>

          </Paper>

        </Grid> */}
      {/* <div className="hold" style={{ flexDirection: "column", justifyContent: "center" }}>
        <div className="about-left" style={{ marginBottom: "0px", paddingBottom: "0px" }}>

        </div>
        <div className="right1" style={{ width: "600px", marginBottom: "0px" }}>
          <Typography variant="h4" style={{ marginLeft: "5px", textAlign: "center" }}>
            Application
          </Typography>
          <p style={{ marginLeft: "8px", textAlign: "center" }}>
            This application helps you measure your oral cancer risk
          </p>
          <hr className="dropdown-divider"></hr>
          <p style={{ marginLeft: "8px", textAlign: "center" }}>
            Refer you to an appropriate centre for further management
          </p>
          <hr className="dropdown-divider"></hr>
          <p style={{ marginLeft: "8px", textAlign: "center" }}>
            Help both the patient and professional to follow up on their lesion
            size and tobacco habits
          </p>
          <hr className="dropdown-divider"></hr>
          <p style={{ marginLeft: "8px", textAlign: "center" }}>
            In future we want to develop a prediction algorithm for oral
            potentially malignant disorders and cancer through AI and ML
            platform
          </p>
        </div>
      </div> 
          </ThemeProvider>

    */}
    </Grid>


  );
};

export default RiskFactors;
