import React, { useState, useEffect } from 'react';
import questions from '../components/questions1.json';
import Question from '../components/Question';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import serverCommunicator from "../api/api";

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step, { stepClasses } from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { Grid, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { formatDiagnostic } from 'typescript';

const StepForm = () => {

  const navigate = useNavigate();

  const categories = Array.from(new Set(questions.map(question => question.category)));

  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({});

  const [mainScore, setMainScore] = useState(0);

  const sendFormData = () => {

    // Calculate the total score based on the selected options
    let newMainScore = 0;
    let formattedAnswers = {};


    for (const i in questions) {
      const question = questions[i]
      const selectedAnswer = formData[question.text];
      const subCategory = question.sub_category || 'default';

      if (question && question.type === "single-choice") {
        // Find the selected option in the question's options array
        const selectedOption = question.options.find(
          (option) => option.choice === selectedAnswer
        );

        if (selectedOption) {
          // Add the score of the selected option to the main score
          newMainScore += selectedOption.score;
        }
      }


      if (!formattedAnswers[subCategory]) {
        if (subCategory == "meta_data")
          formattedAnswers[subCategory] = [];
        else
          formattedAnswers[subCategory] = {};
      }
      if (subCategory == "meta_data") {
        formattedAnswers[subCategory].push(
          { "q": question.text.toLowerCase(), "answer": formData[question.text].toLowerCase() }
        )

        //question_key exists, 
        // e.g. Alcohol
        if (question.question_key != undefined) {
          // case 1: "q":"Alcohol" is missing 
          // case 2: "q":"Alcohol" present and "answer":"no" (formData answer is yes)

          if (formattedAnswers[subCategory].findIndex(obj => obj.q === question.question_key) === -1) {
            formattedAnswers[subCategory].push(
              { "q": question.question_key, "answer": formData[question.text].toLowerCase() }
            )
          }
          else {
            const q_json = formattedAnswers[subCategory].find(obj => obj.q === question.question_key)
            if (q_json && q_json.answer === 'no' && formData[question.text].toLowerCase() === 'yes') {
              q_json.answer = "yes";
            }
          }

        }
      }
      else {

        formattedAnswers[subCategory][question.text.toLowerCase()] = formData[question.text].toLowerCase();

      }

    }

    if (Object.values(formattedAnswers["patient_details"]).some(value => value === "")) {
      alert("Please fill up all information")
    }
    else {
      // Update the main score state
      setMainScore(newMainScore);

      // console.log(newMainScore)

      finalSubmit(formattedAnswers, newMainScore);
    }


  };

  const handleNext = () => {
    if (step < categories.length - 1) {
      // TODO: here we need to add the check
      setStep(step + 1);
    }
    else {
      // console.log(formData)


      // else {
      sendFormData()

      // }
    }
  };

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const handleInputChange = (id, name, value, category) => {
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const initialFormData = {}

    questions.forEach(question => {
      initialFormData[question.text] = question.default_answer || '';
    });
    setFormData(initialFormData)
    // setEditedFormData({ ...formData,[category]:{...formData[category], [name]: value} })
  }, [])

  const finalSubmit = (editedFormData, score) => {

    let reportId;

    serverCommunicator.createScreening()
      .then((response) => {

        const id = response?.data?.id
        reportId = id
        const meta_data = editedFormData['meta_data']
        const patient_details = editedFormData['patient_details']


        serverCommunicator.updateUpload(id, meta_data, patient_details)
          .then((response2) => {

            editedFormData['score'] = score
            editedFormData['reportId'] = reportId

            navigate('/report', { state: editedFormData })
          })
          .catch((err) => {
            // TODO: show a dialog or alert? - Couldn't update
            console.log(err)
          })

      })
      .catch((err) => {
        // TODO: show a dialog or alert? - Couldn't create

        console.log(err)
      });


      // editedFormData['score'] = score
      // editedFormData['reportId'] = 22
      // navigate('/report', { state: editedFormData })

  }
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderStep = (category) => (
    <React.Fragment key={category}>
      {questions
        .filter(question => question.category === category)
        .map(question => (
          <Question
            key={question.id}
            question={question}
            onInputChange={handleInputChange}
            defaultOption={formData[question.text]}
          />
        ))
      }
    </React.Fragment>
  )

  return (

    <Grid
      container
      rowSpacing={1}
      columnSpacing={1}
      direction="row"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
      sx={{ p: isMobile ? 4 : 10 }}>
      <Grid item xs={14} xl={8}>
        <Paper sx={{ p: 2 }}>
          <Stepper activeStep={step} orientation={isMobile ? "vertical" : "horizontal"}>
            {
              categories.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })
            }
          </Stepper>
          {
            step === categories.length ? (
              <></>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'stretch',
                  pl: 2,
                  pt: 5,
                  bgcolor: 'background.paper',
                  borderRadius: 1,
                  flexDirection: 'column'
                }}
              >
                <React.Fragment>
                  <form>

                    {renderStep(categories[step])}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Button
                        color="inherit"
                        disabled={step === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Back
                      </Button>
                      <Button onClick={handleNext}>
                        {step === categories.length - 1 ? 'Finish' : 'Next'}
                      </Button>
                    </Box>
                  </form>

                </React.Fragment>
              </Box>
            )
          }
        </Paper>

      </Grid>
    </Grid >


  );
};

export default StepForm;
