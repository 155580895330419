import { Typography } from "@mui/material";
import Box from '@mui/material/Box';
import { Grid, Stack } from "@mui/material";
import Container from "@mui/material/Container";
import bannerAfter from "../assets/image.png";
import { useTheme } from "@emotion/react";


const HeroSection2 = (props) => {

    const { hero, height, width,  primaryText, secondaryText, subText, button, bannerHeight, alignment, stackJustifyContent, displayCenterAligned } = props;
    const imagestyle = {
        height: height,
        width: width,
        zIndex: 2
    };
    const backgroundImageS = (
        <img style={{ pl: { xs: 0, md: 3, lg: 10, xl: 10 }, position: 'absolute', bottom: 0 }}>{bannerAfter}</img>
    )
    const theme = useTheme();

    return (

        <Box >


            <Box sx={{ background: `linear-gradient(to left, ${theme.palette.tertiary.main}, ${theme.palette.primary.main})`, alignItems: 'center' }} >

                <Grid container>

                    <Grid item xs={12} xl={12} >
                        <Stack direction="row" justifyContent={alignment} sx={{ pl: { xs: 0, md: 3, lg: 12, xl: 12 }, pt: { xs: 3, md: 3, lg: 3, xl: 3 }}}>

                            <img src={hero} style={{
                                height: height,
                                width: width,
                            }} />



                            {!displayCenterAligned ?
                                <Stack direction="column" justifyContent={stackJustifyContent} sx={{ pl: { xs: 0, md: 3, lg: 10, xl: 10 } }}>
                                    <Typography variant="h3" p={2} color="white" align="left"> {primaryText} {secondaryText}</Typography>
                                    <Typography variant="h5" p={2} color="secondary" align="left">{subText}</Typography>
                                    <Typography variant="h5" p={2} align="left">{button}</Typography>

                                </Stack> : <></>}

                        </Stack>


                    </Grid>
                    {displayCenterAligned ?
                    <Grid item xs={12} xl={6} sx={{ p: { xs: 0, md: 3, lg: 10, xl: 10 } }} >
                        <Typography variant="h3" p={2} color="white" align="left">{primaryText} {secondaryText}</Typography>

                        <Typography variant="h5" p={2} color="secondary" align="left">{subText}</Typography>
                        <Typography variant="h5" p={2} align="left">{button}</Typography>

                    </Grid> : <></>}






                </Grid>

                <img src={bannerAfter} style={{
                    height: bannerHeight, width: '100vw'
                }}></img>
                {/* 
                <Stack direction="row" justifyContent="flex-end">

                    <img src={hero} style={{
                        right: 0, height: height, bottom:0,
                        width: width, zIndex:10, position:'relative'
                    }} />
                </Stack> */}

            </Box>


        </Box >







    )
}
export default HeroSection2

