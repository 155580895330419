import React from "react";
import { Grid, Paper, Typography,  useMediaQuery, useTheme } from "@mui/material";
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import DirectionsIcon from '@mui/icons-material/Directions';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import IconAndContent from "../components/icon_and_content";



const AboutApp = () => {

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    // <ThemeProvider theme={theme}>
    <Grid container spacing={5}  m={isMobile?-3:-6} direction="row"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
      sx={{ p: isMobile ? 4 : 10 }}>


      <Grid item xs={12} xl={6} >
        {/* <Box > */}
        <Paper sx={{ p: 6 }}>
          <Typography variant="h3">GCB App</Typography>
          <p style={{ textAlign: "left" }}>
            The GCB Risk App is for the general public to measure their oral
            cancer risk. It is also platform for the professionals to assess,
            follow up and record tobacco consuming patients
          </p>
        </Paper>
        {/* </Box> */}
      </Grid>
      <Grid item xs={12} xl={6}>
        <Grid container rowSpacing={2}>

          <Grid item xs={12} xl={12}>

          <IconAndContent
              icon={<ContentPasteSearchIcon color="primary" fontSize={isMobile?"medium":"large"} />}
              title='Risk Assessment'
              content='The GCB App is designed to help you assess your risk of developing oral cancer by analyzing various factors, including their oral health and tobacco habits.' />

          </Grid>

          <Grid item xs={12} xl={12}>
            <IconAndContent
              icon={<DirectionsIcon color="primary" fontSize="large" />}
              title='Healthcare Centers Referral'
              content='The GCB App also guides you by referring appropriate healthcare centers for further evaluation and management based on your individual reports.' />

          </Grid>

          <Grid item xs={12} xl={12}>

            <IconAndContent
              icon={<MonitorHeartIcon color="primary" fontSize="large" />
              }
              title='Health Monitoring'
              content='Both patients and healthcare professionals benefit from our platform, which not only tracks changes in lesion size but also provides valuable insights into monitoring and managing tobacco habits to improve oral health.' />

          </Grid>

          <Grid item xs={12} xl={12}>

            <IconAndContent
              icon={<PersonSearchIcon color="primary" fontSize="large" />
              }
              title='Prediction'
              content='As part of our future vision, we aim to leverage cutting-edge AI and ML technologies to develop a powerful prediction algorithm for early detection of oral potentially malignant disorders and cancer, enhancing preventive healthcare.' />

          </Grid>

        </Grid>


      </Grid>

    </Grid>

  );
};

export default AboutApp;
