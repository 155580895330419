import React from 'react';
import './Footer.css'; // Import your CSS file with styles

const Footer = () => {
  return (
    <footer className="footer" style={{ backgroundColor: '#611b29' }}>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            {/* Email contact area */}
            <div className="email-contact">
              <h4>Contact Us</h4>
              <p>Phone: 9886697605</p>
              <p>Email: dantaresearch@gmail.com</p>

            </div>
          </div>
          <div className="col-md-6">
            {/* Sentence in the middle */}
            <p className="footer-sentence">
              This website is dedicated to Mrs Hamsaveni and other wives/husbands, mothers/fathers, daughters/sons-in-law, children, and grandchildren who have served the cancer patient in their families. Hats off to the cancer caregivers.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
