import React from 'react';
import { useTheme } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';

const Question = ({ question, onInputChange, defaultOption }) => {

  const handleChange = (value) => {

    onInputChange(question.id, question.text, value, question.category);

  };

  const theme = useTheme();


  return (
    // <form>
    <React.Fragment>
      <span className="text-gray-700">{question.text}</span>
      {question.type === 'text' && (
        <input required type="text" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" defaultValue={defaultOption} onChange={(e) => handleChange(e.target.value)}/>
      )}
      {question.type === 'number' && (
        <input required type="number" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" defaultValue={defaultOption} onChange={(e) => handleChange(e.target.value)} />
      )}
      {question.type === 'tel' && (
        <input required type="tel" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" defaultValue={defaultOption} onChange={(e) => handleChange(e.target.value)} />
      )}
      {question.type === 'single-choice' && (
        <div className='flex'>
          {question.options.map((option) => {

            return (
              <div key={option.choice} className="flex items-center mr-4 mt-2 mb-2">
                <label>
                  
                  <Checkbox
                    type="checkbox"
                    name={question.id}
                    value={option.choice}
                    onChange={() => handleChange(option.choice)}
                    className="form-checkbox"
                    sx={{color:theme.palette.primary}}
                    checked={defaultOption === option.choice}
                  />
                  {option.choice}
                </label>
              </div>
            )
          })}
        </div>
      )}

    </React.Fragment>
  );
};

export default Question;
