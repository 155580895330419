import React from "react";
import { Box, Card, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
// import oralcancer from "../assets/oralcancer.png";
import oralcancer from "../assets/oralcancer2.png";

import erosive from "../assets/erosive.png";
import leukoplakia from "../assets/leukoplakia.png";
import speckled from "../assets/speckled.png";
import submucos from "../assets/submucos.png";
import CircleIcon from '@mui/icons-material/Circle';
import PropTypes from 'prop-types';

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        // bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
        // color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
        border: '1px solid',
        borderColor: (theme) =>
          theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
        borderRadius: 2,
        fontSize: '0.875rem',
        fontWeight: '700',
        boxShadow: 1,
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

const OralCancer = () => {

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid
      container
      spacing={5}
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{p: isMobile? 4: 10}}>

      <Grid item xs={12} xl={8}>
        <Grid container rowSpacing={2}>
          <Grid item xs={12} xl={12}>
            <Paper sx={{ p: 2 }}>

              <Typography variant="h5" color="primary">What is oral cavity?</Typography>

              <List>
                <ListItem>
                  <ListItemIcon> <CircleIcon color="primary" fontSize="extrasmall" /> </ListItemIcon>
                  <ListItemText primary="In simple terms, we call oral cavity as mouth" />
                </ListItem>
                <ListItem>
                  <ListItemIcon> <CircleIcon color="primary" fontSize="extrasmall" /> </ListItemIcon>
                  <ListItemText primary="Our mouth is complex and have many important structures" />
                </ListItem>
                <ListItem>
                  <ListItemIcon> <CircleIcon color="primary" fontSize="extrasmall" /> </ListItemIcon>
                  <ListItemText primary="Along with Teeth, oral cavity has different structures as shown in the image." />

                </ListItem>
                <ListItem>
                  <Grid container justifyContent="center" alignItems="center">
                    <img src={oralcancer} alt="https://en.wikipedia.org/wiki/File:3D_Medical_Animation_Oral_Cavity.jpg" className="oralpic" style={{
                      width: isMobile?'100%' :'60%', 
                      height: 'auto', // Maintain the aspect ratio
                    }} />
                  </Grid>
                </ListItem>
                <ListItem>
                  <ListItemIcon> <CircleIcon color="primary" fontSize="extrasmall" /> </ListItemIcon>
                  <ListItemText primary="All the structures except teeth are covered by a pink coloured covering called oral mucous membrane, which is similar to skin covering our external body." />

                </ListItem>
              </List>

            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} xl={8}>
        <Paper sx={{ p: 2 }}>
          {/* <Grid
            container
            rowSpacing={5}
            columnSpacing={5}
            direction="row"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%">
            <Grid item> */}
          <Typography variant="h5">
            What is oral precancer? Or What are oral potentially
            malignant disorders?
          </Typography>
          {/* </Grid>
          </Grid> */}
          <List>
            <ListItem>
              <ListItemIcon> <CircleIcon color="primary" fontSize="extrasmall" /> </ListItemIcon>
              <ListItemText primary="There are few changes in oral cavity, like having a white
            patch, red and white coloured areas especialy in tobacco users
            and people with difficulty in opening their mouth secondary to
            use of arecanut with or without smokeless tobacco products."/>

            </ListItem>
            <ListItem>
              <ListItemIcon> <CircleIcon color="primary" fontSize="extrasmall" /> </ListItemIcon>
              <ListItemText primary="These changes in oral cavity are considered potentially
            malignant, which means they may be not be cancer right now but
            it has the potential to become malignant in future."/>

            </ListItem>

          </List>
          <Typography variant="h5">
            How do the doctors decide the changes to be potentially
            malignant?
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon> <CircleIcon color="primary" fontSize="extrasmall" /> </ListItemIcon>
              <ListItemText primary="Doctors followed up discolorations, or patches inside the
            mouth for long term, it was noted that it transformed to
            cancer."/>

            </ListItem>
            <ListItem>
              <ListItemIcon> <CircleIcon color="primary" fontSize="extrasmall" /> </ListItemIcon>

              <ListItemText primary="The small patches of discolorations or ulcers or erosions noted were also noted around
the margins of proven cancers of the mouth."/>

            </ListItem>
            <ListItem>
              <ListItemIcon> <CircleIcon color="primary" fontSize="extrasmall" /> </ListItemIcon>
              <ListItemText primary="Under the microscope too, the above lesion had similar
            cellular features and molecular changes as in cancer were also
            noted here even though they had not become cancerous."/>

            </ListItem>
            <ListItem>
              <ListItemIcon> <CircleIcon color="primary" fontSize="extrasmall" /> </ListItemIcon>
              <ListItemText primary="Such changes in the mouth were termed previously as “Oral
            Precancer”, now it is termed as “Oral Potentially Maligant
            Disorders”"/>

            </ListItem>
          </List>
        </Paper>
      </Grid>

      <Grid item xs={12} xl={8}>
        <Paper sx={{ p: 2 }}>
          {/* <Grid
                  container
                  rowSpacing={5}
                  columnSpacing={5}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                  width="100%">
                  <Grid item>
                    
                  </Grid>
                  <Grid item> */}
          <Typography variant="h5">What is cancer?</Typography>
          <Typography sx={{ p: 2 }}>Cancer is a disease of a cell/tissue or organ where the cell
            multiplies uncontrollably and forms a mass which is of no use to
            the body but makes use of the all the faculties/infrastructures of
            body to grow and spread locally and spread distantly too!</Typography>
          <List>
            <ListItem>
              <ListItemIcon> <CircleIcon color="primary" fontSize="extrasmall" /> </ListItemIcon>
              <ListItemText primary="Most common cause of cancer could be tobacco usage in case of
            mouth cancer, lung cancer and others too. In case of skin
            cancer UV radiation from Sunlight. In case of bone cancer,
            long term radiaition exposure is spoke as a cause. But truly
            speaking it is multifactorial where genetics, epigenetics,
            etc." />

            </ListItem>
            <ListItem>
              <ListItemIcon> <CircleIcon color="primary" fontSize="extrasmall" /> </ListItemIcon>
              <ListItemText primary="Cancer is of the mucous membrane of mouth is termed as Oral
            squamous cell carcinoma and represents over 90% of different
            cancers of oral cavity." />

            </ListItem>
            <ListItem>
              <ListItemIcon> <CircleIcon color="primary" fontSize="extrasmall" /> </ListItemIcon>
              <ListItemText primary="So oral cancer is used loosely to describe the ‘The Oral
            squamous cell carcinoma’" />

            </ListItem>
            <ListItem>
              <ListItemIcon> <CircleIcon color="primary" fontSize="extrasmall" /> </ListItemIcon>
              <ListItemText primary="In India, oral cancer represents a major health problem
            constituting up to 40% of all cancers and is the most
            prevalent cancer in males and the third most prevalent in
            females." />

            </ListItem>
            <ListItem>
              <ListItemIcon> <CircleIcon color="primary" fontSize="extrasmall" /> </ListItemIcon>
              <ListItemText primary="Tobacco is considered the etiology is almost 80% of cases of
            oral squamous cell carcinoma, making it the most preventable
            type of cancer." />

            </ListItem>
            <ListItem>
              <ListItemIcon> <CircleIcon color="primary" fontSize="extrasmall" /> </ListItemIcon>
              <ListItemText primary="With this application, our aim is to reduce the prevalence of
            oral cancer." />

            </ListItem>
          </List>

        </Paper>
      </Grid>


      <Grid item xs={12} xl={8}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h5">
            Few Oral Potentially malignant disorders
          </Typography>
          <Grid container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={2} >
            <Grid item xs={12} sm={12} md={6} xl={3}>
              <Card sx={{ p: 2 }} elevation={1} >
                <img src={leukoplakia} alt="" />


                <Typography m={1}>
                  This is called Homogenous leukoplakia, here the patient
                  used tobacco in smoked form.
                </Typography>
              </Card>

            </Grid>

            <Grid item xs={12} sm={12} md={6} xl={3} >
              <Card sx={{ p: 2 }} elevation={1} >
                <img src={submucos} alt="" />

                <Typography m={1}>
                  Oral Submucous fibrosis Difficulty to open the mouth Loss
                  of elasticity in the cheek Uvula has shruken
                </Typography>
              </Card>

            </Grid>

            <Grid item xs={12} sm={12} md={6} xl={3} >
              <Card sx={{ p: 2 }} elevation={1} >
                <img src={erosive} alt="" />

                <Typography m={1}>
                  Erosive Oral Lichenplanus
                </Typography>
              </Card>

            </Grid>

            <Grid item xs={12} sm={12} md={6} xl={3}>
              <Card sx={{ p: 2 }} elevation={1} >
                <img src={speckled} alt="" />

                <Typography m={1}>
                  Speckled Leukoplakia
                </Typography>
              </Card>

            </Grid>
          </Grid>

        </Paper>
      </Grid >
      <Grid item xs={12} xl={8}>
        <Paper sx={{ p: 2 }}>
          <Grid
            container
            rowSpacing={5}
            columnSpacing={5}
            direction="row"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%">
            <Grid item>



              <p style={{ marginLeft: "8px", textAlign: "center" }}>
                This application will mainly stress on Oral leukoplakia and its clinical
                variants and Oral submucous fibrosis as they are the most common
                oral potentially malignant disorder
              </p>

            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid >
  );
};

export default OralCancer;
