import React from "react";
import { Grid, Paper, Typography, Box, Card, Button, Stack } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery
} from "@mui/material";
import footerimage from "../assets/footer.png";
import { useLocation, useNavigate } from "react-router-dom";
import html2pdf from "html2pdf.js";
import { useTheme } from "@emotion/react";
import HeroSection from "../components/heroSection";
import header from "../assets/header.png";
const ReportNew = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const data = location.state;

  const handleDownloadPDF = () => {

    const element = document.getElementById('pdf-report');
    const { pdfWidth, pdfHeight } = document.body.getBoundingClientRect();
    const options = {
      margin: 5,
      filename: 'download.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 5 },
      jsPDF: { unit: 'mm', format: 'a3', orientation: 'portrait' },
    };

    html2pdf(element, options);
    navigate('/')
  }
  const { name, age, gender, state } = data['patient_details']
  const { score, reportId } = data


  const navigate = useNavigate();
  return (
    <Grid
      container
      margin={0}
      rowSpacing={1}
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{ p: isMobile ? 2 : 10, pageBreakInside: 'avoid' }}

    >

      <Grid item xs={12} xl={8} >
        <Grid container spacing={2} id="pdf-report" >
          <Grid item xs={12} xl={12}>
            <Paper sx={{ p: 2 }}>
              {/* <Typography variant="h4">ORAL CANCER RISK ASSESSMENT</Typography> */}
              {/* <Typography variant="h5" sx={{ color: "black", textEmphasis: "bold" }}>REPORT</Typography> */}
              <Grid container>
                <Grid item xs={10} md={10} lg={10} xl={10}>
                  <img src={header} style={{ width: '100%' }} />

                </Grid>
                <Grid item xs={2} md={2} lg={2} xl={2}>
                  {/* TODO: Add the right most number etc */}
                </Grid>
              </Grid>
              <Grid container mt={2} spacing={isMobile ? 0.8 : 2}>
                <Grid item xs={6} md={4} lg={4} xl={4}>
                  <Typography variant="h6" sx={{ color: 'black', textAlign: "justify" }}>Participant Information:</Typography>

                </Grid>
                <Grid item xs={6} md={8} lg={8} xl={8}>
                  <Typography variant="h6" sx={{ color: 'black', textAlign: "justify" }}>Name: {" "} <span style={{ textDecoration: "underline", fontStyle: "light", textTransform: "capitalize" }}>
                    {name}
                  </span>
                  </Typography>
                  <Typography variant="h6" sx={{ color: 'black', textAlign: "justify" }}>Age: {" "} <span style={{ textDecoration: "underline", fontStyle: "light", textTransform: "capitalize" }}>
                    {age}
                  </span></Typography>
                  <Typography variant="h6" sx={{ color: 'black', textAlign: "justify" }}>Gender: {" "} <span style={{ textDecoration: "underline", fontStyle: "light", textTransform: "capitalize" }}>
                    {gender}
                  </span></Typography>
                  <Typography variant="h6" sx={{ color: 'black', textAlign: "justify" }}>Demography:{" "} <span style={{ textDecoration: "underline", fontStyle: "light",textTransform: "capitalize" }}>
                    {state}
                  </span></Typography>

                </Grid>

                <Grid item xs={12} md={4} lg={4} xl={4}>
                  <Typography variant="h6" sx={{ color: 'black', textAlign: "justify" }}>Assessment Result:</Typography>
                </Grid>

                <Grid item xs={12} md={8} lg={8} xl={8}>
                  <Typography mt={1} mb={1} variant="h6" sx={{ color: 'black', textAlign: "justify" }}> Participant Score{" "}:
                    <span style={{ textDecoration: "underline", fontStyle: "light" }}>
                      {score}
                    </span>
                  </Typography>

                  <Paper
                    sx={{
                      width: isMobile ? '50px' : '80px',
                      height: isMobile ? '50px' : '80px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: "50%",
                      backgroundColor: score > 15 ? "red" : score >= 10 && score <= 15 ? "#eed202" : "green",
                    }}

                  >
                    <Typography variant="h6" color="white">
                      {score > 15 ? ">15" : score >= 10 && score <= 15 ? "10-15" : "<10"}
                    </Typography>
                  </Paper>


                  {/* <Typography style={{ textAlign: "left", fontSize: isMobile ? "small" : "inherit" }} mt={1} mb={1}>
                    based on your score, we recommend the following:
                  </Typography>

                  <TableContainer
                    component={Paper}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ borderRight: "1px solid #ddd" }}>
                            <p style={{ textAlign: "left", fontWeight: "bold" }}>
                              Score Range
                            </p>
                          </TableCell>
                          <TableCell>
                            <p style={{ textAlign: "left", fontWeight: "bold" }}>
                              Recommendation
                            </p>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell style={{ borderRight: "1px solid #ddd" }}>
                            {">15"}
                          </TableCell>
                          <TableCell>
                            Visit Oral Pathologist/Oral Surgeon/Oral Medicine Specialist
                            immediately
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ borderRight: "1px solid #ddd" }}>
                            10-15
                          </TableCell>
                          <TableCell>
                            Visit Oral Pathologist/Oral Surgeon/Oral Medicine Specialist
                            in the next 1 Month
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ borderRight: "1px solid #ddd" }}>
                            {"<10"}
                          </TableCell>
                          <TableCell>
                            Visit Oral Pathologist/Oral Surgeon/Oral Medicine Specialist
                            in the next 2 Months
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer> */}

                  <Typography variant="h6" sx={{ color: 'black', textAlign: "justify" }} mt={1} mb={1}> Recommended Action:

                  </Typography>

                  <Typography style={{ textAlign: "left", fontSize: isMobile ? "small" : "inherit" }} mt={1} mb={1}> {score > 15 ? "Visit your dentist nearby immediately for a screening; Ask for meeting specialty consultants like Oral Medicine Specialist, Oral and Maxillofacial Surgeon or Oral pathologist." : (score >= 10 && score <= 15 ? "Visit your dentist nearby within a span of one month; Ask for meeting specialty consultants like Oral Medicine Specialist, Oral and Maxillofacial Surgeon or Oral pathologist." : "Appreciate your efforts to stay healthy but consider visiting your dentist nearby within a span of two month; Ask for meeting specialty consultants like Oral Medicine, Oral and Maxillofacial Surgeon or Oral pathologist.")}
                  </Typography>

                  <Typography variant="h6" sx={{ color: 'black', textAlign: "justify" }} mt={1} mb={1}> Assessment Date:

                  </Typography>

                  <Typography style={{ textAlign: "left", fontSize: isMobile ? "small" : "inherit" }} mt={1} mb={1}>
                    {new Date().toLocaleString() + ""}
                  </Typography>


                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <p style={{ fontSize: "small", width: "100%", marginX: "10px", paddingX: "10px" }}>
                    <span style={{ fontWeight: "bold" }}>Disclaimer:</span> This report
                    offers an assessment based on the scores presented and is intended for
                    informational purposes only. It is not to be construed as medical
                    advice or a diagnostic tool. Professional consultation with a dentist,
                    physician, or appropriate healthcare professional is paramount for any
                    medical-related questions or concerns. The guidance herein serves as a
                    supplementary reference and should not be the singular basis for
                    health-related decisions. While efforts have been made to ensure the
                    reliability of this information, the assessor disclaims responsibility
                    for any actions or omissions that are taken based on this report.
                  </p>
                  <p style={{ fontSize: "small", width: "100%", marginX: "10px", paddingX: "10px" }}>
                    Oral Medicine Specialist, Oral maxillofacial surgeons and oral pathologist are specialties that are often associated with diagnosing and managing oral potentially malignant disorders and cance
                  </p>

                </Grid>

                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <footer className="footer1">
                    <img
                      src={footerimage}
                      className="footerimage"
                      alt=""
                      style={{ borderRadius: "10px" }}
                    />
                  </footer>
                </Grid>

              </Grid>



            </Paper>
          </Grid>

        </Grid>


      </Grid>
      <Grid item xs={12} xl={8}>
        <Stack direction="row" justifyContent="space-between">
          <Button justifyContent="flex-start" variant="contained" color="secondary" onClick={handleDownloadPDF}>Download Report</Button>
          {/* <Button variant="contained" color="secondary" onClick={() => navigate('/camera', {state: reportId})}>Get AI Based Oral Assessment</Button> */}
        </Stack>

        {/* <Stack direction="row" justifyContent="flex-end">

          
        </Stack> */}

      </Grid>


    </Grid>

  );
};

export default ReportNew;
